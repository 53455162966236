<template>
  <div class="managewgtypes">
    <Header></Header>

    <v-container class="my-3">
      <v-row>
        <v-col cols="12">
          <h1 class="text-h2">Manage Worker Group Types</h1>
          <v-divider class="mt-8 mb-8"></v-divider>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            gravida varius orci, in ultrices nunc pellentesque sit amet. Orci
            varius natoque penatibus et magnis dis parturient montes, nascetur
            ridiculus mus. Etiam nisl justo, cursus sit amet justo nec, cursus
            placerat erat. Lorem ipsum dolor sit amet, consectetur adipiscing
            elit.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card-title class="px-0">
            <h2 class="text-h3">My Worker Group Types</h2>
            <v-spacer></v-spacer>
            <!-- <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search Worker Group Types"
              single-line
              hide-details
            ></v-text-field>-->
          </v-card-title>

          <v-data-table
            :loading="dataLoading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="wgtypes"
            :disable-sort="true"
            :options.sync="options"
            :server-items-length="itemsTotal"
            :footer-props="{
              itemsPerPageOptions: [10]
            }"
            class="elevation-5 align-center"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat color="grey lighten-2">
                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="secondary"
                      dark
                      rounded
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon left>mdi-plus</v-icon>Create New Worker Group Type
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <h3 class="text-h4">{{ formTitle }}</h3>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-form ref="form" v-model="valid" lazy-validation>
                              <v-text-field
                                filled
                                v-model="editedItem.name"
                                label="Worker Group Name"
                                :rules="[v => !!v || 'Name is required']"
                                required
                              ></v-text-field>
                            </v-form>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close">Cancel</v-btn>
                      <v-btn color="primary" text @click="save(editedItem)"
                        >Save Worker Group Type</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>

          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";
import WGtypeService from "@/services/wgtype.service";

export default {
  components: {
    Header
  },

  data: () => ({
    dataLoading: true,
    options: {},
    itemsTotal: 0,
    currentPage: 1,
    lastPage: null,
    snackbar: false,
    alert: "",
    dialog: false,
    valid: true,
    search: "",
    headers: [
      { text: "Worker Group Type Name", align: "start", value: "name" },
      { text: "Template", align: "start", value: "template" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" }
    ],
    wgtypes: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      template: ""
    },
    defaultItem: {
      name: "",
      template: ""
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Worker Group Type"
        : "Edit Worker Group Type";
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      async handler() {
        const orgId = this.getOrgId();
        if (orgId && this.lastPage !== null) {
          this.getPagedWGTypes();
        }
      },
      deep: true
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  mounted() {},

  methods: {
    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },

    async initialize() {
      this.getPagedWGTypes();
    },

    async getPagedWGTypes() {
      this.dataLoading = true;

      // const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const { page, itemsPerPage } = this.options;

      const response = await WGtypeService.find({
        query: {
          page: page
        }
      });

      this.wgtypes = response.data.data.slice();
      this.itemsTotal = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      // disabling sort for phase 1
      // if (sortBy.length === 1 && sortDesc.length === 1) {
      //   this.wgtypes = this.wgtypes.sort((a, b) => {
      //     const sortA = a[sortBy[0]]
      //     const sortB = b[sortBy[0]]

      //     if (sortDesc[0]) {
      //       if (sortA < sortB) return 1
      //       if (sortA > sortB) return -1
      //       return 0
      //     } else {
      //       if (sortA < sortB) return -1
      //       if (sortA > sortB) return 1
      //       return 0
      //     }
      //   })
      // }

      if (itemsPerPage > 0) {
        this.wgtypes = this.wgtypes.slice(
          (this.currentPage - 1) * itemsPerPage,
          this.currentPage * itemsPerPage
        );
      }

      this.dataLoading = false;
    },

    getOrgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    },

    editItem(item) {
      this.editedIndex = this.wgtypes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      const index = this.wgtypes.indexOf(item);

      confirm("Are you sure you want to delete this Worker Group Type?") &&
        this.wgtypes.splice(index, 1);
      await WGtypeService.remove({
        id: item.id
      });
      this.itemsTotal--;
      this.showSnackbar(item.name + " has been deleted");
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save(item) {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.editedIndex > -1) {
          // update item
          const data = {
            name: item.name,
            template: "Default"
          };

          const WGtypeId = this.wgtypes[this.editedIndex].id;
          await WGtypeService.update({
            id: WGtypeId,
            data: data
          });

          Object.assign(this.wgtypes[this.editedIndex], this.editedItem);
          this.showSnackbar(item.name + " has been updated");
        } else {
          // create item
          const data = {
            name: item.name,
            template: "Default"
          };

          const response = await WGtypeService.create({
            data: data
          });

          this.wgtypes.push(response.data);
          this.itemsTotal++;
          this.showSnackbar(item.name + " has been created");
        }
        this.close();
      }
    }
  }
};
</script>
