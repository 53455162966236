var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"managewgtypes"},[_c('Header'),_c('v-container',{staticClass:"my-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h2"},[_vm._v("Manage Worker Group Types")]),_c('v-divider',{staticClass:"mt-8 mb-8"}),_c('p',[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam gravida varius orci, in ultrices nunc pellentesque sit amet. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam nisl justo, cursus sit amet justo nec, cursus placerat erat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"px-0"},[_c('h2',{staticClass:"text-h3"},[_vm._v("My Worker Group Types")]),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-5 align-center",attrs:{"loading":_vm.dataLoading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.wgtypes,"disable-sort":true,"options":_vm.options,"server-items-length":_vm.itemsTotal,"footer-props":{
            itemsPerPageOptions: [10]
          },"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"grey lighten-2"}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","dark":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Create New Worker Group Type ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('h3',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"filled":"","label":"Worker Group Name","rules":[function (v) { return !!v || 'Name is required'; }],"required":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.save(_vm.editedItem)}}},[_vm._v("Save Worker Group Type")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}])}),_c('v-snackbar',{attrs:{"right":"","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.alert)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }